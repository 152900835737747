/* eslint-disable @typescript-eslint/no-unused-vars */
import { extendBaseTheme } from "@chakra-ui/react";
import { getGlobalStyling } from "./styles/global";
import { getColors } from "./variables/colors";
import { breakpoints } from "./variables/breakpoints";
import { fonts } from "./variables/fonts";
import { radii } from "./variables/radii"
import { sizes } from "./variables/sizes";
import { space } from "./variables/space";
import { transition } from "./variables/transition";
import { text } from "./components/Text";
import { Button } from "./components/Button";
import { Heading } from "./components/Heading";
import { Link } from "./components/Link";
import { DeepPartial } from "utility-types";
import { fontSizes } from "./variables/fontSizes";
import { letterSpacings } from "./variables/letterSpacings";
import { Popover } from "./components/Popover";
import { Checkbox } from "./components/Checkbox";
import { Select } from "./components/Select";
import { Input } from "./components/Input";
import deepmerge from "deepmerge";
import chakraTheme from "@chakra-ui/theme";

const {
	Button: ChakraButton,
	Link: ChakraLink,
	Heading: ChakraHeading,
	Menu: ChakraMenu,
	Popover: ChakraPopover,
	Input: ChakraInput,
	Checkbox: ChakraCheckbox,
	Radio: ChakraRadio,
	Select: ChakraSelect
} = chakraTheme.components;

const defaultTheme = (rtl: boolean) => ({
	name: "Default",
	direction: rtl ? "rtl" : "ltr",
	colors: getColors(rtl),
	breakpoints,
	radii,
	...fonts(rtl),
	...fontSizes(rtl),
	...letterSpacings(rtl),
	sizes,
	space,
	transition,
	styles: {
		...getGlobalStyling(rtl)
	},
	//this can be used to change variables based on _rtl/_ltr
	components: {
		Text: {
			...text(rtl),
		},
		Heading: {
			...ChakraHeading,
			...Heading(rtl)
		},
		Button: {
			...ChakraButton,
			...Button
		},
		Link: {
			...ChakraLink,
			...Link(rtl)
		},
		Menu: {
			...ChakraMenu,
		},
		Popover: {
			...deepmerge(ChakraPopover, Popover())
		},
		Input: {
			...deepmerge(ChakraInput, Input)
		},
		Radio: ChakraRadio,
		Checkbox: {
			...deepmerge(ChakraCheckbox, Checkbox)
		},
		Select: {
			...deepmerge(ChakraSelect, Select)
		}
	},
});

export type Theme = DeepPartial<ReturnType<typeof defaultTheme>> & {
	name: string;
};

export function getDefaultTheme(rtl: boolean) {
	const theme = defaultTheme(rtl);
	return extendBaseTheme(theme) as Theme;
}

// used to generate chakra-types and storybook
export default getDefaultTheme(false);