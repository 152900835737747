import React, { FC, useContext, useEffect } from "react";
import { RouteData, sc, SitecoreContextValue, useSitecoreContext } from "~/foundation/Jss";
import deepEqual from "deep-equal";
import { Helmet } from "react-helmet-async";
// import { VisitorIdentification } from "@sitecore-jss/sitecore-jss-react";
// import config from "~/temp/config";
import { Fonts } from "~/foundation/Theme";
import { MenuBase, PageBase } from "~/foundation/Multisite/generated-types";
import { PageDarkThemeBase, PageThemeBase } from "~/foundation/Theme/generated-types";
import {
	getPageSpecificMetaTitle,
	getPageSpecificMetaDescription,
	getPageSpecificMetaImage,
	validateMetaDataString
} from "./getMetaData";
import { Favicon } from "./Head/Favicon";
import { ParallaxProvider } from 'react-scroll-parallax';
import { ColorThemeContext } from "~/foundation/Theme/ColorThemeContext";
import { SuitcasePageBase } from "~/feature/Content/generated-types";
import { Suitcase } from "~/foundation/Suitcase";
import { ProgressBar } from "~/foundation/Components/ProgressBar";
import { PageGraphics } from "~/foundation/Components/PageGraphics";
import { useQueryString } from "~/foundation/Utils/useQueryString";
import { googleTagManagerIframe, googleTagManagerScriptTag, googleTagManagerDeniedStorageScriptTag, pushDataLayer } from "~/foundation/Tracking/GoogleTagManager";
import { getColorTheme } from "./AppRoot";
import { RenderSchema } from "./Schemas/RenderSchema";
import { SearchProvider } from "~/feature/Search/SearchProvider";
import { OneTrust } from "~/foundation/OneTrust/OneTrust";
import { SkipNavContent } from "@chakra-ui/react";
import { SkipToContent } from "~/feature/Navigation/SkipToContent/SkipToContent";
import { SlideInTagSearch } from "~/feature/Search/SlideInTagSearch";
import { AudioPlayer } from "~/feature/Article/AudioPlayer";
import { sitecoreContentConstants } from "~/foundation/SitecoreContent";
import { RestrictedAccess } from "~/foundation/RestrictedAccess";

/*
	APP LAYOUT
	This is where the app's HTML structure and root placeholders should be defined.

	All routes share this root layout by default (this could be customized in RouteHandler),
	but components added to inner placeholders are route-specific.
*/

type LayoutProps = {
	route: RouteData<PageBase & MenuBase & PageThemeBase & SuitcasePageBase & PageDarkThemeBase>;
	isRtl: boolean;
}

const Layout: FC<LayoutProps> = ({ route, isRtl }) => {
	const { sitecoreContext } = useSitecoreContext();
	const queryString = useQueryString();
	const [currentColorTheme, setColorTheme] = useContext(ColorThemeContext);

	const metaBrowserTitle = sitecoreContext.custom.settings.siteBrowserTitle;

	const metaTitle = validateMetaDataString(route.fields?.metaTitle?.value) || getPageSpecificMetaTitle(route) || validateMetaDataString(route.fields?.menuTitle?.value) || validateMetaDataString(route.fields?.pageTitle?.value) || route.name || "Saudi Aramco";
	const metaDesc = validateMetaDataString(route.fields?.metaDescription?.value) || getPageSpecificMetaDescription(route) || validateMetaDataString(route.fields?.metaTitle?.value) || validateMetaDataString(route.fields?.pageTitle?.value) || "Saudi Aramco";
	const metaImageObj = route.fields?.metaImage?.value?.src ? route.fields?.metaImage?.value : getPageSpecificMetaImage(route);

	let metaImageArray: React.ReactNode[] = [];
	if (metaImageObj?.src) {
		metaImageArray = [
			<meta key={0} property="og:image" content={metaImageObj.src} />,
			<meta key={1} property="og:image:type" content="image/webp" />,
			<meta key={2} property="og:image:width" content={metaImageObj.width} />,
			<meta key={3} property="og:image:height" content={metaImageObj.height} />,
			<meta key={4} property="og:image:alt" content={metaImageObj.alt} />,
			<meta key={5} name="twitter:image" content={metaImageObj.src} />
		];
	}

	const navigationColorTheme = route?.fields?.navigationColorTheme?.value || route?.fields?.colorTheme?.value;
	const footerColorTheme = route?.fields?.footerColorTheme?.value || route?.fields?.colorTheme?.value;

	const googleAnalyticsId = sitecoreContext.custom.settings.googleAnalyticsId;
	const googleEnableConsentMode = sitecoreContext.custom.settings.googleEnableConsentMode;
	const googleGrantFunctionalityStorage = sitecoreContext.custom.settings.googleGrantFunctionalityStorage;

	useEffect(() => {
		pushDataLayer(() => ({
			event: "page_view",
			page_type: route.templateName
		}));

		const colorTheme = getColorTheme(sitecoreContext, route);

		if (currentColorTheme.name !== colorTheme.name) {
			setColorTheme(colorTheme);
		}

	}, [route]);

	return (
		<ParallaxProvider>
			<Helmet
				htmlAttributes={{
					dir: isRtl ? "rtl" : "ltr",
					dataEditmode: sitecoreContext.pageEditing ? "inEditmode" : "",
					lang: sitecoreContext.language
				}}
				script={[
					{
						innerHTML: `window.dataLayer = window.dataLayer || [];`
					},
					{
						innerHTML: googleTagManagerDeniedStorageScriptTag(googleGrantFunctionalityStorage, !googleEnableConsentMode || queryString.disableThirdParty === "true")
					},
					{
						innerHTML: googleTagManagerScriptTag(googleAnalyticsId, queryString.disableThirdParty === "true")
					}
				]}
				noscript={[
					{
						innerHTML: googleTagManagerIframe(googleAnalyticsId, queryString.disableThirdParty === "true")
					}
				]}
			>
				<title>
					{validateMetaDataString(route.fields?.pageTitle?.value) || metaTitle} {metaBrowserTitle}
				</title>

				<link rel="canonical" href={sitecoreContext.custom?.absoluteUrl}/>
				<meta name="description" content={metaDesc}/>
				{/*Facebook Meta Tags*/}
				<meta property="og:url" content={sitecoreContext.custom?.absoluteUrl}/>
				<meta property="og:type" content="website"/>
				<meta property="og:title" content={metaTitle.toString()}/>
				<meta property="og:description" content={metaDesc}/>
				{metaImageArray}
				{/*Twitter Meta Tags*/}
				<meta name="twitter:title" content={metaTitle.toString()}/>
				<meta name="twitter:description" content={metaDesc}/>
				<meta name="twitter:card" content="summary_large_image"/>

				{sitecoreContext.custom?.hrefLangList.map((hrefModel, id) => (
					<link key={id} rel="alternate" href={hrefModel.href} hrefLang={hrefModel.hrefLang}/>
				))}
			</Helmet>
			{sitecoreContext.custom.settings.cookieConsent.useOneTrust && !sitecoreContext.pageEditing && queryString.disableThirdParty !== "true" &&
				<OneTrust sitecoreContext={sitecoreContext}/>}
			<Favicon/>
			<RenderSchema route={route} context={sitecoreContext}/>
			{/*
				VisitorIdentification is necessary for Sitecore Analytics to determine if the visitor is a robot.
				If Sitecore XP (with xConnect/xDB) is used, this is required or else analytics will not be collected for the JSS app.
				For XM (CMS-only) apps, this should be removed.

				VI detection only runs once for a given analytics ID, so this is not a recurring operation once cookies are established.
				*/}
			{/*
			This is commented out until we know if they'll use Sitecore Tracking
			{!config.connected && (
				<VisitorIdentification />
			)}*/}
			<Fonts rtl={isRtl}/>
			{/* root placeholder for the app, which we add components to using route data */}
			<div>
				<SkipToContent/>
				{/* Casting route to RouteData is only necessary here in the Layout file */}
				<sc.Placeholder name="header" rendering={route as RouteData} appendKey={navigationColorTheme}/>
				<ProgressBar isRtl={isRtl}/>
				<SkipNavContent/>
				<main className="container">
					<SearchProvider active={sitecoreContext.custom.hasSearchFilter}>
						<sc.Placeholder name="page-top" rendering={route as RouteData} appendKey={route.itemId}/>
						<sc.Placeholder name="page-layout" rendering={route as RouteData} appendKey={route.itemId}/>
					</SearchProvider>
					<sc.Placeholder name="page-bottom" rendering={route as RouteData} appendKey={route.itemId}/>
					{route.fields?.enablePageGraphics?.value && (
						<PageGraphics/>
					)}
				</main>
				<sc.Placeholder name="footer" rendering={route as RouteData} appendKey={footerColorTheme}/>
			</div>
			{route.fields?.disableSuitcase?.value === false && ( // has to be === false
				<Suitcase/>
			)}
			{route.templateId === sitecoreContentConstants.templateIds.pageTypes.magazineArticleTemplateId &&
				<AudioPlayer sitecoreContext={sitecoreContext as SitecoreContextValue}/>
			}
			{sitecoreContext.custom.settings.disclaimerInfo &&
				<RestrictedAccess/>
			}
			<SlideInTagSearch/>
			<sc.Placeholder name="page-script" rendering={route as RouteData} appendKey={route.itemId}/>
		</ParallaxProvider>
	);
};

// We don't want to re-render `Layout` when route is changed but layout data is not loaded
// Layout will be re-rendered only when layout data is changed
const propsAreEqual = (prevProps: LayoutProps, nextProps: LayoutProps) => {
	return deepEqual(prevProps.route, nextProps.route);
};

export default React.memo(Layout, propsAreEqual);