/* eslint-disable @typescript-eslint/no-unused-vars */
import { ColorTheme } from "../";
import logo from "~/foundation/Assets/svg/logos/logo-light.svg";
import React from "react";

export const getBritishRacingGreenColorTheme = (rtl: boolean): ColorTheme => ({
	name: "British Racing Green",
	colors: {
		ribbon: {
			bg: "themeBackgroundColors.britishRacingGreen",
			color: "primary.white",
			border: "primary.neutralGrey",
			linkColor: "primary.white"
		},
		wordExplainer: {
			bg: "primary.white",
			color: "primary.text",
			activeColor: "primary.aramcoLinkBlue"
		},
		header: {
			bg: "themeBackgroundColors.britishRacingGreen",
			bgInactive: "navigationColors.darkBgTransparent",
			color: "primary.neutralGrey",
			activeColor: "primary.aramcoBlue",
			hygieneColor: "primary.neutralGrey",
			hygieneGlobalContacts: "primary.neutralGrey",
			burgerMenuColor: "primary.white",
		},
		siteNavigation: {
			gradient: "siteNavigation.darkTheme.gradient",
			bg: "siteNavigation.darkTheme.bg",
			color: "siteNavigation.darkTheme.color",
		},
		footer: {
			bg: "themeBackgroundColors.britishRacingGreen",
			color: "primary.white"
		},
		breadcrumb: {
			linkColor: "aramcoBreadcrumb.darkTheme.linkColor",
			activeColor: "aramcoBreadcrumb.darkTheme.activeColor",
			aramcoA: "aramcoBreadcrumb.darkTheme.aramcoA",
			iconColor: "aramcoBreadcrumb.darkTheme.iconColor",
			linkColorCurrentPage: "aramcoBreadcrumb.darkTheme.linkColorCurrentPage",
		},
		generatedCard: {
			bg: "primary.white",
			color: "primary.text",
			linkColor: "primary.aramcoLinkBlue"
		},
		tags: {
			color: "primary.neutralGrey"
		}
	},
	Logo: () => <img src={logo} alt="aramco logo" width="123" height="25" />,
	FooterLogo: () => <img src={logo} alt="aramco logo" width="136" height="29" />
});