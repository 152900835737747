import React, { FC, useContext, useEffect, useState } from "react";
import { RichText } from '@sitecore-jss/sitecore-jss-react';
import { type RichTextProps as SitecoreRichTextProps } from '@sitecore-jss/sitecore-jss-react/types/components/RichText';
import { type RichTextField, useSitecoreContext } from "..";
import parse, { Element, HTMLReactParserOptions } from 'html-react-parser';
import { ColorThemeContext } from "~/foundation/Theme/ColorThemeContext";
import { WordExplainer } from "~/foundation/Components/WordExplainer";

type RichTextProps = SitecoreRichTextProps & {
	field?: RichTextField;
	enableWordExplainer?: boolean;
};

const RichTextWrapper: FC<RichTextProps> = ({ enableWordExplainer, ...props }) => {
	const { sitecoreContext } = useSitecoreContext();
	const isRtl = sitecoreContext.custom.settings.isRtl;
	const [theme] = useContext(ColorThemeContext);
	const [richTextComponent, setRichTextComponent] = useState<JSX.Element>(<RichText {...props} />);

	if (sitecoreContext.pageEditing) {
		return <RichText {...props} />;
	}

	if (!props?.field?.value) {
		return <></>;
	}

	useEffect(() => {
		let index = 0;

		const wordExplainers = sitecoreContext.custom.wordExplainers;
		let richTextValue = props.field?.value || "";

		if (enableWordExplainer) {
			if (sitecoreContext.custom.settings.isRtl) { // regex with \\b doesn't always work as expected for non-Latin scripts
				for (const word of wordExplainers) {
					const originalKey = word.key;
					let escapedKey = originalKey;

					if (originalKey.startsWith("(") && originalKey.endsWith(")")) {
						escapedKey = originalKey.substring(1, originalKey.length - 1);
						richTextValue = richTextValue.replace(originalKey, escapedKey)
					}

					const regex = new RegExp(`(?!<(?:a|img)[^>]*>)${escapedKey}(?![^<]*(?:</a>|/>))`, "i");
					richTextValue = richTextValue.replace(regex, () => `<span class="word-explainer">${originalKey}</span>`);
				}
			} else {
				for (const word of wordExplainers) {
					const originalKey = word.key;
					let escapedKey = originalKey;

					if (originalKey.startsWith("(") && originalKey.endsWith(")")) {
						escapedKey = originalKey.substring(1, originalKey.length - 1);
						richTextValue = richTextValue.replace(originalKey, escapedKey)
					}

					const regex = new RegExp(`(?!<(?:a|img)[^>]*>)\\b${escapedKey}\\b(?![^<]*(?:</a>|/>))`, "i");
					richTextValue = richTextValue.replace(regex, () => `<span class="word-explainer">${originalKey}</span>`);
				}
			}
		}

		const parseOptions: HTMLReactParserOptions = {

			replace: (domNode) => {
				index++;

				if (domNode instanceof Element && domNode.attribs) {
					if (domNode.type === "tag" && domNode.name === "span" && domNode.attribs?.class === "word-explainer") {
						const text = (domNode.children?.[0] as any)?.data; // eslint-disable-line
						const wordExplainer = wordExplainers.find(x => x.key.toLowerCase().trim() === text?.toLowerCase()?.trim())

						if (wordExplainer && typeof text === "string") {
							return <WordExplainer isRtl={isRtl} theme={theme} text={text} wordExplainer={wordExplainer} />;
						}
					}

					if (index === 1 && domNode.type === "tag") {
						domNode.attribs.style = "margin-top: 0;";
					}
				}
			}
		};

		const Component = parse(richTextValue, parseOptions) as JSX.Element;
		setRichTextComponent(Component);
	}, []);

	return richTextComponent;
};

export default RichTextWrapper;