import React, { FC } from "react";
import { ReactComponent as ArrowLeft } from "~/foundation/Assets/svg/icons/ArrowLeft.svg";
import { ReactComponent as ArrowRight } from "~/foundation/Assets/svg/icons/ArrowRight.svg";
import { ReactComponent as ArrowUp } from "~/foundation/Assets/svg/icons/ArrowUp.svg";
import { ReactComponent as ArrowDown } from "~/foundation/Assets/svg/icons/ArrowDown.svg";
import { ReactComponent as Calendar } from "~/foundation/Assets/svg/icons/Calendar.svg";
import { ReactComponent as Checkmark } from "~/foundation/Assets/svg/icons/Checkmark.svg";
import { ReactComponent as Close } from "~/foundation/Assets/svg/icons/Close.svg";
import { ReactComponent as Darkmode } from "~/foundation/Assets/svg/icons/Darkmode.svg";
import { ReactComponent as Document } from "~/foundation/Assets/svg/icons/Document.svg";
import { ReactComponent as Down } from "~/foundation/Assets/svg/icons/Dropdown.svg";
import { ReactComponent as Download } from "~/foundation/Assets/svg/icons/Download.svg";
import { ReactComponent as ExternalLink } from "~/foundation/Assets/svg/icons/ExternalLink.svg";
import { ReactComponent as Filter } from "~/foundation/Assets/svg/icons/Filter.svg";
import { ReactComponent as Headphones } from "~/foundation/Assets/svg/icons/Headphones.svg";
import { ReactComponent as Lightmode } from "~/foundation/Assets/svg/icons/Lightmode.svg";
import { ReactComponent as Next } from "~/foundation/Assets/svg/icons/Next.svg";
import { ReactComponent as Pause } from "~/foundation/Assets/svg/icons/Pause.svg";
import { ReactComponent as Play } from "~/foundation/Assets/svg/icons/Play.svg";
import { ReactComponent as Plus } from "~/foundation/Assets/svg/icons/Plus.svg";
import { ReactComponent as Search } from "~/foundation/Assets/svg/icons/Search.svg";
import { ReactComponent as Skewer } from "~/foundation/Assets/svg/icons/Skewer.svg";
import { ReactComponent as Upload } from "~/foundation/Assets/svg/icons/Upload.svg";
import { ReactComponent as Up } from "~/foundation/Assets/svg/icons/Up.svg";
import { ReactComponent as Facebook } from "~/foundation/Assets/svg/icons/socials/Facebook.svg";
import { ReactComponent as Instagram } from "~/foundation/Assets/svg/icons/socials/Instagram.svg";
import { ReactComponent as LinkedIn } from "~/foundation/Assets/svg/icons/socials/LinkedIn.svg";
import { ReactComponent as Twitter } from "~/foundation/Assets/svg/icons/socials/Twitter.svg";
import { ReactComponent as TwitterX } from "~/foundation/Assets/svg/icons/socials/TwitterX.svg";
import { ReactComponent as Youtube } from "~/foundation/Assets/svg/icons/socials/Youtube.svg";
import { ReactComponent as Star } from "~/foundation/Assets/svg/icons/Star.svg";
import { ReactComponent as AudioPlay } from "~/foundation/Assets/svg/icons/AudioPlay.svg";
import { IconProps as ChakraIconProps, Icon as ChakraIcon } from "@chakra-ui/react";

const defaultVariant = {
	h: "auto",
	fill: "currentColor",
};

export const iconVariants = {
	ArrowDown: {
		as: ArrowDown,
		w: "icons.md",
		...defaultVariant,
	},
	ArrowLeft: {
		as: ArrowLeft,
		w: "icons.md",
		...defaultVariant,
	},
	ArrowRight: {
		as: ArrowRight,
		w: ["icons.md", null, "icons.lg"],
		...defaultVariant,
	},
	ArrowUp: {
		as: ArrowUp,
		w: ["icons.md", null, "icons.lg"],
		...defaultVariant,
	},
	Calendar: {
		as: Calendar,
		w: "icons.md",
		'sx': {
			fill: "transparent",
			'.calendar--fill': {
				fill: "currentColor"
			},
			'.calendar--stroke': {
				stroke: "currentColor"
			}
		},
		...defaultVariant,
	},
	Checkmark: {
		as: Checkmark,
		w: "icons.md",
		...defaultVariant,
	},
	Close: {
		as: Close,
		w: "icons.md",
		...defaultVariant,
	},
	Darkmode: {
		as: Darkmode,
		w: "icons.md",
		...defaultVariant,
	},
	Document: {
		as: Document,
		w: "icons.md",
		sx: {
			fill: "transparent",
			stroke: "currentColor",
		},
		...defaultVariant,
	},
	Down: {
		as: Down,
		w: "icons.md",
		...defaultVariant,
	},
	Download: {
		as: Download,
		w: "icons.md",
		...defaultVariant,
	},
	ExternalLink: {
		as: ExternalLink,
		w: "icons.md",
		...defaultVariant,
	},
	Filter: {
		as: Filter,
		w: "icons.md",
		'sx': {
			'.filter--fill': {
				fill: "currentColor"
			},
			'.filter--stroke': {
				stroke: "currentColor"
			}
		},
		...defaultVariant,
	},
	Headphones: {
		as: Headphones,
		w: "icons.md",
		...defaultVariant
	},
	Lightmode: {
		as: Lightmode,
		w: "icons.md",
		...defaultVariant,
	},
	Next: {
		as: Next,
		w: "icons.md",
		...defaultVariant,
	},
	Play: {
		as: Play,
		w: "icons.md",
		'sx': {
			fill: "transparent",
			stroke: "currentColor",
		},
		...defaultVariant,
	},
	Pause: {
		as: Pause,
		w: "icons.md",
		...defaultVariant,
	},
	Plus: {
		as: Plus,
		w: "icons.md",
		...defaultVariant,
	},
	Search: {
		as: Search,
		w: "icons.md",
		'sx': {
			'path': {
				fill: "currentColor"
			}
		},
		...defaultVariant,
	},
	Skewer: {
		as: Skewer,
		w: "icons.md",
		...defaultVariant,
	},
	Up: {
		as: Up,
		w: "icons.md",
		...defaultVariant,
	},
	Upload: {
		as: Upload,
		w: "icons.md",
		...defaultVariant,
	},
	//social icons
	Facebook: {
		as: Facebook,
		w: "icons.md",
		...defaultVariant,
	},
	Instagram: {
		as: Instagram,
		w: "18px",//"icons.md",
		sx: {
			fill: "transparent",
			stroke: "currentColor",
			'circle': {
				fill: 'currentColor'
			}
		},
		...defaultVariant,
	},
	LinkedIn: {
		as: LinkedIn,
		w: "icons.md",
		sx: {
			fill: "transparent",
			stroke: "currentColor",
			'circle': {
				fill: 'currentColor'
			}
		},
		...defaultVariant,
	},
	Twitter: {
		as: Twitter,
		w: "icons.md",
		sx: {
			fill: "transparent",
			stroke: "currentColor",
			'circle': {
				fill: 'currentColor'
			}
		},
		...defaultVariant,
	},
	TwitterX: {
		as: TwitterX,
		w: "14px",//"icons.md",
		...defaultVariant,
	},
	Youtube: {
		as: Youtube,
		w: "18px",//"icons.md",
		sx: {
			fill: "transparent",
			'rect': {
				stroke: "currentColor",
			},
			'path': {
				fill: "currentColor",
			}
		},
		...defaultVariant,
	},
	Star: {
		as: Star,
		w: "icons.md",
		sx: {
			fill: "transparent",
			'path': {
				fill: "currentColor",
			}
		},
		...defaultVariant,
	},
	AudioPlay: {
		as: AudioPlay,
		w: "icons.md",
		'sx': {
			fill: "transparent",
			stroke: "currentColor",
		},
		...defaultVariant,
	},
};

export type IconType = keyof typeof iconVariants;

type IconProps = ChakraIconProps & {
	variant: IconType;
};

export const Icon: FC<IconProps> = ({ variant, ...iconProps }) => {
	const SvgIcon = variant;

	return SvgIcon ? <ChakraIcon {...iconVariants[variant]} {...iconProps} /> : <></>;
};